


























































































import Vue from 'vue'

interface RefundDetail {
  transactionNumber: number
  paidFor: string
  paidBy: string
  amount: string
}

interface TabItem {
  title: string
  subtitle: string
}

interface ComponentData {
  items: RefundDetail[]
  columns: Record<string, string>[]
  tabs: TabItem[]
  activeTab: TabItem
}

export default Vue.extend({
  data(): ComponentData {
    return {
      items: Array.from(Array(10), (_, index) => ({
        transactionNumber: index + 1,
        paidFor: 'Akakpo Armelle',
        paidBy: 'Akakpo Armelle',
        amount: '15 000 F'
      })),
      columns: [
        {
          label: 'N° transaction',
          field: 'transactionNumber'
        },
        {
          label: 'Payer Pour',
          field: 'paidFor'
        },
        {
          label: 'Payer Par',
          field: 'paidBy'
        },
        {
          label: 'Montant',
          field: 'amount'
        },
        {
          label: '',
          field: '_action',
          align: 'right'
        }
      ],
      tabs: Array.from(Array(10), (_, index) => ({
        title: `Groupe ${index + 1}`,
        subtitle: `${5 + index + 1} membres`
      })),
      activeTab: {
        title: 'Groupe 1',
        subtitle: '6 membres'
      }
    }
  },

  methods: {
    onTabClick(index: number) {
      this.activeTab = {
        title: this.tabs[index].title,
        subtitle: this.tabs[index].subtitle
      }
    }
  }
})
